import React, { useEffect, useState } from "react"
import axios from "axios"
import Loader from "../components/loader"
import { Link } from "gatsby"

const PopularVideos = () => {
  const [popularVideos, setPopularVideos] = useState()
  useEffect(() => {
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCTv936ogD_8j8RIO-S5cp4g&maxResults=4&order=rating&key=AIzaSyDWgk9I2fuIOzdHOrH9izAVXPBu-IkmSi0"
      )
      .then(response => {
        setPopularVideos(response.data.items)
      })
  }, [])

  if (popularVideos === undefined) {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center">
          <Loader />
          <h3>Loading...</h3>
        </div>
      </React.Fragment>
    )
  } else {
    const dataVideos = popularVideos.map(video => {
      return (
        <div
          key={`${video.id.videoId}`}
          className="col-12 col-md-6 col-lg-3 mt-5"
        >
          <Link
            className="text-decoration-none text-dark"
            to={`/60-seconds/${video.snippet.title
              .toLowerCase()
              .replace(/ /g, "-")
              .replace(/---/g, "-")
              .replace(/-&-/g, "-")
              .replace(/,/g, "")
              .replace(".", "")
              .replace(/"/g, "")
              .replace(/!/g, "")
              .replace(/\?\?/g, "")
              .replace("stress?", "stress")
              .replace("bercerai?", "bercerai")
              .replace("sorga-?", "sorga")
              .replace(/'/g, "")
              .replace(/:/g, "")
              .replace(/&/g, "-and-")
              .replace("-?", "")
              .replace(/#/g, "")
              .replace(/\s+/g, "-")
              .replace(/[^\w\-]+/g, "")
              .replace(/\-\-+/g, "-")
              .replace(/^-+/, "")
              .replace(/-+$/, "")}`}
          >
            <div
              className="position-relative w-100"
              style={{ paddingTop: "56.26%" }}
            >
              <img
                className="position-absolute w-100 rounded shadow"
                style={{ height: "100%", top: "0", left: "0" }}
                src={video.snippet.thumbnails.high.url}
                alt={video.snippet.title}
              />
            </div>
            <div className="my-3" />
            <h6 className="">{video.snippet.title}</h6>
          </Link>
        </div>
      )
    })
    return (
      <React.Fragment>
        <div className="row">{dataVideos}</div>
      </React.Fragment>
    )
  }
}

export default PopularVideos
