import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"
import Loader from "./loader"

const FeaturedVideo = () => {
  const { featuredVideo } = useStaticQuery(graphql`
    {
      featuredVideo: youtubeVideo(
        channelTitle: { eq: "Gilbert Lumoindong TV" }
      ) {
        title
        videoId
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="position-relative w-100" style={{ paddingTop: "56.25%" }}>
        <iframe
          className="position-absolute w-100 rounded shadow-lg"
          style={{ top: "0", left: "0", height: "100%" }}
          src={`https://www.youtube.com/embed/${featuredVideo.videoId}`}
          frameBorder="0"
        ></iframe>
      </div>
    </React.Fragment>
  )
}

export default FeaturedVideo
