import React from "react"

const Loader = () => {
  return (
    <React.Fragment>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </React.Fragment>
  )
}

export default Loader
