import React, { useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import axios from "axios"
import FeaturedVideo from "../components/featuredVideo"
import PopularVideos from "../components/popularVideos"
import { Link } from "gatsby"
import VideoTemplatePage from "../templates/videoTemplate"
import NewestVideos from "../components/newestVideos"
import VlogVideos from "../components/vlogVideos"

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO title="Explore" />
      <Layout>
        <div className="container">
          <h1 className="text-center">
            Temukan 60 Detik Bersama Ps.Gilbert Lumoindong
          </h1>
          <div className="my-5" />
          <FeaturedVideo />
          <div className=" py-5" />
          <h2 className="text-center">Terpopuler</h2>
          <div className="mt-3 " />
          <PopularVideos />
          <div className=" py-5" />
          <h2 className="text-center">Video Terbaru</h2>
          <NewestVideos />
          <div className=" py-5" />
          <h2 className="text-center">Vlog #KamuHebat</h2>
          <VlogVideos />
          <div className=" py-5" />
          <div className=" py-5" />
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
